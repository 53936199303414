/* eslint-disable camelcase */
import { useStaticQuery, graphql } from 'gatsby';
import { node } from 'prop-types';

export const useEventsFilters = () => {
  const {
    allTaxonomyTermEventTags,
    allTaxonomyTermSeries,
    allNodeEvent
  } = useStaticQuery(
    graphql`
      query EventsFilters {
        allTaxonomyTermEventTags(sort: { fields: [weight], order: ASC }) {
          nodes {
            name
            drupal_id
            field_hide_event_type_filter
            relationships {
              parent {
                name
              }
            }
          }
        }
        allTaxonomyTermSeries(sort: { fields: [weight], order: ASC }) {
          nodes {
            name
            drupal_id
            field_hide_event_type_filter
          }
        }
        allNodeEvent {
          nodes {
            relationships {
              field_venue {
                title
                drupal_id
              }
              field_series {
                drupal_id
                name
              }      
            }
          }
        }
      }
    `
  );

  /*
  @todo: possibly need to re-write allNodeEvent with filter like:  allNodeEvent(filter: { isOver: { eq: false } })
  */

  const tags = allTaxonomyTermEventTags.nodes
    .filter((node) => (node.relationships.parent.length > 0 ? false : true))
    .map((node) => {
      return {
        sort_item: node.drupal_id,
        name: node.name
      };
    });

  /* This feels a bit strange but if "hide filter type" is TRUE
     don't include the filter in the tags. */
  const series = allTaxonomyTermSeries.nodes
    .filter((node) => node.field_hide_event_type_filter === true ? false : true)
    .map((node) => {
      return {
        sort_item: node.drupal_id,
        name: node.name
      };
    });

  // Don't need Events with no venues in filters.
  const filterNullVenues = allNodeEvent.nodes.filter((node) => {
    return node.relationships.field_venue !== null;
  });

  // De-duplicate our new array.
  const deDupedVenues = Array.from(new Set(filterNullVenues.map((node) => node.relationships.field_venue.title))).map((title) => {
    return filterNullVenues.find(
      (node) => node.relationships.field_venue.title === title
    );
  });

  const venues = deDupedVenues
    .filter((node) => node.relationships.field_venue)
    .map((venue) => {
      return {
        sort_item: venue.relationships.field_venue.drupal_id,
        title: venue.relationships.field_venue.title
      };
    });

  return [
    {
      title: 'Event Type',
      field: 'field_parent_tags',
      data: tags
    },
    {
      title: 'Series',
      field: 'field_series',
      data: series
    },
    {
      title: 'Venue',
      field: 'field_venue',
      data: venues
    }
  ];
};
