import React, { useState } from 'react';
import { useEventsData } from '../hooks/use-events-data';
import { useEventsFilters } from '../hooks/use-events-filters';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Card from '../components/card';
import ListingLayout from '../components/listing-layout';

import FilterContentList from '../components/filter-content-list';

import styles from '../components/listing-layout/listing-layout.module.scss';

const EventsListing = () => {
  const originalEvents = useEventsData();
  const filters = useEventsFilters();

  const [eventsState, updateEvents] = useState(originalEvents);

  const eventImgSizes =
    '(max-width: 576px) 98vw, (max-width: 795px) 90vw, 344px';
  return (
    <Layout>
      <SEO title="Inside Sandy Springs Events" />
      <FilterContentList
        data={originalEvents}
        filters={filters}
        listings={eventsState}
        bubbleUpState={updateEvents}
        style={{ marginBottom: '95px' }}
        desc={'Filter Events'}
      >
        <ListingLayout>
          {eventsState.map((event, index) => (
            <Card
              key={index}
              title={event.title}
              image={event.largeImage}
              srcset={event?.srcSet?.srcs?.ratio16x9}
              sizes={eventImgSizes}
              tag={event.tag}
              type={event.type}
              parentTag={event.parentTag}
              date={event.dateShort}
              summary={event.summary}
              url={event.url}
              className={styles.eventCard}
            />
          ))}
        </ListingLayout>
      </FilterContentList>
    </Layout>
  );
};

export default EventsListing;
